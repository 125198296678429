<template>
  <!-- 
  <div class="box">
    <img src="../../assets/img/logo.png" alt="" class="logoImg">
    <p class="title centerTxt">商贸配对</p>
    <div class="info1 centerTxt">
      <span>为助力买家，高效选品，精准对接</span>
      <span>【SZCW商贸配对】服务全面升级！</span>
      <span>首实行365天全年化运营</span>
      <span>品牌新品不间断上新，专业选品官全天在线</span>
      <span>展览会现场特设【选品服务中心】 9号馆9A09</span>
      <span>优享“展前提前配对，展期轻松选品”</span>
      <span>请填写表格，开启您的定制选品之旅：</span>
    </div>

    <p class="btnTitle centerTxt">意向产品需求</p>
    
    <template v-for="(item, idx) in btnList">
      <van-button :key="idx" type="info" size="normal" color="#048DAD" class="btn" @click="toEdit(item)">{{item}}</van-button>
    </template>
  </div>
  -->
  
  <div class="box1">
    <img src="../../assets/img/1.jpg" alt="" class="img">
    <div class="relative">
      <img src="../../assets/img/2.jpg" alt="" class="img">
      <div class="btn-box absolute">
        <template v-for="(item, idx) in btnList">
          <van-button :key="idx" type="info" size="normal" color="#4127C6" class="btn1" @click="toEdit(item)">
            <span style="letter-spacing: 2px;">{{item}}</span>
          </van-button>
        </template>
      </div>
    </div>
  </div>
  
</template>

<script>
  import { getState } from '@/api/accentFurnitures.js';
  export default {
    data() {
      return {
        btnList: ['精品家具','软装饰品','建材卫浴','时尚灯饰','设计材料','国际品牌']
      }
    },
    created() {
      document.title = '商贸配对';
      let {source = '', unionid = ''} = this.$route.query;
      localStorage.setItem('source', source)
      localStorage.setItem('unionid', unionid || this.uuid())
      unionid && this.getStatus()
    },
    methods: {
      // 唯一标识符
      uuid() {
      	var s = [];
      	var hexDigits = "0123456789abcdef";
      	// 标识符长度36位
      	for (var i = 0; i < 36; i++) {
      		s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      	}
      	s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      	s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      	s[8] = s[13] = s[18] = s[23] = "-";
      
      	return s.join("");
      },
      getStatus() {
        let unionid = localStorage.getItem('unionid')
        let params = {
          unionid: unionid
        }
        getState(params).then(res => {
          if (res.data != null) {
            this.$router.push({
              path: '/otherResult'
            })
          }
        })
      },
      toEdit(name) {
        this.$router.push({
          path: '/accentFurnitures',
          query: {
            produceType: name
          }
        })
      },
    },
  }
</script>

<style scoped>
  .box {
    background-image: url('../../assets/img/baground.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    box-sizing: border-box;
    /* padding-bottom: .4rem; */
    padding-bottom: 2.5vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .box1 {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    box-sizing: border-box;
    /* padding-bottom: .4rem; */
    padding-bottom: 2.5vh;

  }

  .centerTxt {
    text-align: center;
  }

  .logoImg {
    width: 40%;
    display: block;
    /* margin: .36rem auto .2rem; */
    margin: 4vh auto 3vh;
  }

  .title {
    font-size: .26rem;
    /* margin-bottom: .2rem; */
    margin-bottom: 2.4vh;
  }

  .info1 {
    font-size: .14rem;
    /* margin-bottom: .2rem; */
    margin-bottom: 2.4vh;
    line-height: .26rem;
  }
  .info1 span {
    display: block;
  }

  .btnTitle {
    /* margin-bottom: .2rem; */
    margin-bottom: 2.4vh;
    font-size: .16rem;
    font-weight: 600;
  }

  .btn {
    font-size: .16rem;
    height: .4rem;
    border-radius: .05rem;
    width: 60%;
    /* margin: 0 auto .16rem; */
    margin: 0 auto 2.2vh;
    display: block;
  }
  
  .relative {
    position: relative;
  }
  .absolute {
    position: absolute;
  }
  
  .img {
    width: 100%;
    height: auto;
  }
  
  .btn-box {
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }
  
  .btn1 {
    font-size: .16rem;
    height: .5rem;
    border-radius: .05rem;
    width: 78%;
    /* margin: 0 auto .16rem; */
    margin: 0 auto 2.8vh;
    display: block;
    font-family: '微软雅黑';
  }  
</style>
